import {useState} from 'react'
import CategoryToggler from './CategoryToggler'
import {searchProductTemplates} from '../lib/api/product-templates'
import {TProductTemplate} from '../lib/api/product-templates'
import Button from './Button/Button'
import {usePrintAll} from '../lib/utils/usePrintAll'
import WarehouseDropdown from './WarehouseDropdown'
import {useWarehouse} from '../context/warehouse'

export default function BatchEdit() {
  const [text, setText] = useState('')
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false)
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const [products, setProducts] = useState<TProductTemplate[]>([])

  const search = async () => {
    setProducts([])
    setIsLoadingProducts(true)
    const lines = text
      .split('\n')
      .map((s) => s.trim())
      .filter(Boolean)
    for (const keyword of lines) {
      const products = await searchProductTemplates(keyword)
      setProducts((prev) => [...prev, ...products.data])
    }
    setIsLoadingProducts(false)
  }

  const {printAllProducts, loading: publishingProducts, progress, target} = usePrintAll({products})
  const warehouses = useWarehouse()
  const onAdd = () => printAllProducts().then(() => search())

  return (
    <div className="flex flex-col gap-4">
      <span className="text-lg">Modifică mai multe produse de-odată</span>
      <ul>
        <li>- Introdu codurile de bare separate prin enter (linie nouă)</li>
        <li>- Apasă butonul search, se vor căuta toate produsele</li>
        <li>- Verifică că lista e cea corectă</li>
        <li>- Apasă pe butonul Categoriei dorite</li>
      </ul>
      <textarea placeholder={`BARCODE1\nBARCODE2\nLPN123`} onChange={(e) => setText(e.target.value)} className="flex flex-grow" rows={10} />

      {isLoadingProducts ? <div>Loading ...</div> : <Button onClick={search}>Search</Button>}
      {products.length ? (
        <>
          <p>Rezultate: {products.length}</p>
          <ul style={{maxWidth: '90vw'}}>
            {products.map((product, idx) => (
              <li key={idx} className="truncate text-ellipsis">
                - <span>{product.name}</span>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p>No produts found</p>
      )}

      {!!products.length && !isLoadingProducts && (
        <>
          <h2>Modificare categorie</h2>
          {isUpdatingCategory ? (
            <div>Updating category ...</div>
          ) : (
            <CategoryToggler
              onStart={() => setIsUpdatingCategory(true)}
              onEnd={() => {
                setIsUpdatingCategory(false)
                search()
              }}
              products={products}
            />
          )}

          <h2>Adăugare/mutare în depozit</h2>
          {publishingProducts ? (
            <p>
              Loading ... {progress} / {target}
            </p>
          ) : (
            <>
              <WarehouseDropdown {...warehouses} autoLoadFirst />
              <Button onClick={onAdd}>Add all</Button>
            </>
          )}
        </>
      )}
    </div>
  )
}
