import {AxiosResponse} from 'axios'
import {CHEAP_PRICE_LIMIT, PRICING_V2, VAT} from '../../config'
import {roundToTwo} from '../utils/number'
import {execute_kw} from './client'
import {Categories, CategoriesV2, getCategory, getWebsiteCategory} from './public-categories'
import {TWarehouse, getWarehousesCached} from './warehouse'

const fields = [
  'id',
  'name',
  'active',
  'barcode',
  'website_url',
  'list_price',
  'is_published',
  'qty_available',
  'property_stock_inventory',
  'public_categ_ids',
  'lpn',
  'description',
  'description_sale',
  'lpn',
  'palet',
  'palet_no',
  'image_128',
  'product_variant_ids',
  'asin',
  'dashy_processed',
  'categ_id',
  'location_id',
]

export type TProductTemplate = {
  id: Number
  name: string
  asin: string
  active: boolean
  description: string
  description_sale: string
  barcode: string | false
  website_url: string
  is_published: boolean
  qty_available: number
  image_128: string
  list_price: number
  product_variant_ids: Array<number>
  categ_id: Array<number>
  public_categ_ids: Array<number>
  dashy_tries: number
  lpn: string
  palet: string
  palet_no: number
  property_stock_inventory?: [number, string]
}

/**************************************************************
 * Stock management
 *************************************************************/
async function productReplenish(product: TProductTemplate, quantity = 1, warehouse?: TWarehouse) {
  const location_id = warehouse?.lot_stock_id[0] ?? product.property_stock_inventory?.[0]

  return execute_kw('stock.quant', 'create', [
    {
      product_id: product?.product_variant_ids?.[0],
      product_tmpl_id: product?.id,
      quantity,
      location_id,
    },
  ])
}

async function getWarehouseUpdateData(product: TProductTemplate, warehouse: TWarehouse) {
  // remove all website categories for locations
  const warehouses = await getWarehousesCached()
  const warehouseWebsiteCategoryIds: number[] = []
  for (let i = 0; i < warehouses.data.length; i++) {
    const w = warehouses.data[i]
    const category = await getWebsiteCategory(w.name as string)
    if (category) warehouseWebsiteCategoryIds.push(category.id)
  }
  const public_categ_ids = (product.public_categ_ids ?? []).filter((x) => !warehouseWebsiteCategoryIds.includes(x))

  // add warehouse category if it exists
  const publicLocationCategory = await getWebsiteCategory(warehouse.name as string)
  if (publicLocationCategory?.id) public_categ_ids.push(publicLocationCategory.id)

  return {
    property_stock_inventory: warehouse.lot_stock_id?.[0],
    public_categ_ids,

    // location_id: warehouse.view_location_id[0],
    // warehouse_id: warehouse.id,
    // "property_stock_production": [15, "Virtual Locations/Production"],
    // "property_stock_inventory": [14, "Virtual Locations/Inventory adjustment"],
    // "location_id": false,
    // "warehouse_id": false,
  }
}

export async function changeWarehouse(product: TProductTemplate, warehouse: TWarehouse) {
  return execute_kw('product.template', 'write', [[product.id]], {
    vals: {
      ...(await getWarehouseUpdateData(product, warehouse)),
    },
  })
}

/**************************************************************
 * Product management
 *************************************************************/
export async function setProductCategory(product: TProductTemplate, categ_id: string | number, active: boolean = true) {
  return execute_kw('product.template', 'write', [[product.id]], {
    vals: {categ_id, active},
  })
}

async function publishProductTemplate(
  product: TProductTemplate,
  {
    barcode,
    is_published = true,
    palet_no = undefined,
    warehouse,
  }: {
    barcode: string
    palet_no?: number
    is_published?: boolean
    warehouse: TWarehouse
  }
) {
  const categ = await getCategory(
    PRICING_V2
      ? CategoriesV2.ORIGINAL_PRICE
      : roundToTwo(product.list_price * VAT) <= CHEAP_PRICE_LIMIT || product.list_price * VAT < CHEAP_PRICE_LIMIT
      ? Categories.FIXED_PRICE
      : Categories.VARIABLE_PERCENT
  )

  return execute_kw('product.template', 'write', [[product.id]], {
    vals: {
      categ_id: categ.id,
      is_published,
      available_in_pos: true,
      allow_out_of_stock_order: false,
      create_date: new Date().toISOString(),
      website_sequence: -Date.now() / 1000,
      barcode,
      active: true,
      type: 'product',
      ...(await getWarehouseUpdateData(product, warehouse)),
      ...(palet_no ? {palet_no} : null),
    },
  })
}

async function renewProductTemplate(product: TProductTemplate, warehouse: TWarehouse) {
  return execute_kw('product.template', 'write', [[product.id]], {
    vals: {
      create_date: new Date().toISOString(),
      website_sequence: -Date.now() / 1000,
      active: true,
      ...(await getWarehouseUpdateData(product, warehouse)),
    },
  })
}

export async function productReception(
  product: TProductTemplate,
  {is_published = true, palet_no = undefined, warehouse}: {is_published: boolean; palet_no?: number; warehouse: TWarehouse}
) {
  const barcode = product.barcode || Date.now().toString()
  if (!product.barcode || !product.is_published || !product.palet_no)
    await publishProductTemplate(product, {barcode, is_published, palet_no, warehouse})
  else await renewProductTemplate(product, warehouse)

  product.barcode = barcode
  product.is_published = true
  product.palet_no = palet_no ?? product.palet_no

  // retrieve new product data if it was archived before
  if (!product.active) {
    const res = await searchProductTemplates(product.barcode)
    product = res.data[0]
  }

  if (!product.qty_available) await productReplenish(product, 1, warehouse)

  return product
}

export async function productToggleVisibility(id, visible) {
  return execute_kw('product.template', 'write', [[id]], {
    vals: {
      is_published: visible,
      qty_available: 1,
    },
  })
}

/**************************************************************
 * Searching
 *************************************************************/
export function searchProductTemplates(keyword): Promise<AxiosResponse<Array<TProductTemplate>>> {
  return execute_kw(
    'product.template',
    'search_read',
    [
      // pendingOnly
      //   ? ["&", ["barcode", "=", false], ["lpn", "ilike", keyword]]
      [
        ['active', 'in', [true, false]], // archived

        '|',
        '|',
        ['barcode', 'ilike', keyword],
        ['lpn', 'ilike', keyword],
        ['palet', '=', keyword],
      ],
    ],
    {fields, limit: 200}
  )
}

export function productChangePrice(product: TProductTemplate, list_price: number) {
  return execute_kw('product.template', 'write', [[product.id]], {
    vals: {
      list_price,
    },
  })
}
