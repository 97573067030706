import cx from 'classnames'
import {setProductCategory, TProductTemplate} from '../lib/api/product-templates'
import {usePriceCategories, usePriceCategoriesV2} from '../lib/api/public-categories'
import Button from './Button'
import {toast} from 'react-toastify'
import {PRICING_V2, VARIABLE_PERCENT_2_CATEGORY} from '../config'

const usePricing = () => {
  if (PRICING_V2) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const categories = usePriceCategoriesV2()
    if (!categories.loaded) return {loaded: false}

    const options = [
      {label: 'original', category: categories.originalPrice, c: '!bg-blue-500'},
      {label: 'variabil', category: categories.variablePercent, c: '!bg-yellow-500'},
      {label: 'pret mic', category: categories.smallPrice, c: '!bg-pink-500'},
      {label: '50%', category: categories.percent50, c: '!bg-green-500'},
      {label: '60%', category: categories.percent60, c: '!bg-green-500'},
      {label: '70%', category: categories.percent70, c: '!bg-green-500'},
      {label: '80%', category: categories.percent80, c: '!bg-green-500'},
      {label: '90%', category: categories.percent90, c: '!bg-green-500'},
      {label: 'defect', category: categories.defect, c: '!bg-gray-500'},
    ]
    return {options, categories, loaded: true}
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const categories = usePriceCategories()
  if (!categories.loaded) return {loaded: false}

  const options: {
    label: string
    category: any
    c: typeof categories.fixedPercent
  }[] = [
    {label: '% fix', category: categories.fixedPercent, c: '!bg-blue-500'},
    {label: '% var', category: categories.variablePercent, c: '!bg-yellow-500'},
    VARIABLE_PERCENT_2_CATEGORY && {label: '% var 2', category: categories.variablePercent2, c: '!bg-orange-500'},
    {label: 'pret fix', category: categories.fixedPrice, c: '!bg-red-500'},
    {label: 'pret mic', category: categories.smallPrice, c: '!bg-pink-500'},
    {label: 'defect', category: categories.defect, c: '!bg-gray-500'},
  ].filter(Boolean) as any

  return {options, categories, loaded: true}
}

export default function CategoryToggler({
  products,
  onEnd,
  onStart,
}: {
  products: TProductTemplate[]
  onEnd: () => void
  onStart?: () => void
}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {categories, loaded, options} = usePricing()
  if (!loaded) return <div>Loading ...</div>
  const ids = [...new Set(products.map((p) => p?.categ_id).flat())]

  const onClick = async (category) => {
    try {
      onStart?.()
      for (const p of products) await setProductCategory(p, category, category !== categories?.defect?.id)
      onEnd?.()
      toast('Produse actualizate')
    } catch (e) {
      toast('Eroare la actualizare produse')
    }
  }

  return (
    <div className="flex flex-row gap-4">
      {options?.map?.(({label, category, c}) => (
        <Button
          key={label}
          onClick={() => onClick(category?.id as number)}
          className={cx(c, ids.includes(category?.id ?? -1) && 'font-extrabold underline')}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}
