import {useEffect, useState} from 'react'
import {execute_kw} from './client'
import {exec} from 'child_process'
import {AxiosPromise, AxiosResponse} from 'axios'

export type TProductCategory = {
  id: number
  name: string
}

export enum Categories {
  VARIABLE_PERCENT = 'Toate',
  VARIABLE_PERCENT_2 = 'Variabil 2',
  FIXED_PERCENT = 'Procent fix',
  FIXED_PRICE = 'Pret fix',
  SMALL_PRICE = 'Pret mic',
  DEFECT = 'Defect',
}

export enum CategoriesV2 {
  ORIGINAL_PRICE = 'Pret original',
  VARIABLE_PERCENT = 'Variabil',
  SMALL_PRICE = 'Pret unic',
  DEFECT = 'Defect',

  PERCENT_50 = '50%',
  PERCENT_60 = '60%',
  PERCENT_70 = '70%',
  PERCENT_80 = '80%',
  PERCENT_90 = '90%',
}

const cache = {}
let loadingCategories: AxiosPromise<TProductCategory[]> | boolean
async function loadCategories() {
  if (loadingCategories === true) return
  if (loadingCategories) return loadingCategories
  loadingCategories = execute_kw('product.category', 'search_read', [[]]) as AxiosPromise<TProductCategory[]>

  const results = await loadingCategories
  results.data.forEach((cat) => {
    cache[cat.name] = cat
  })

  loadingCategories = true
  return true
}

export async function getCategory(name: Categories | CategoriesV2): Promise<TProductCategory> {
  await loadCategories()
  if (cache[name]) return cache[name]

  execute_kw('product.category', 'create', [{name}])
  throw new Error(`No ${name} category found, attempted to create one, please refresh page`)
}

// const promise = new Promise(async (resolve, reject) => {
//   // const results = await execute_kw('product.category', 'search_read', [[['name', '=', name]]])
//   const results = (await execute_kw('product.category', 'search_read', [[]])) as AxiosResponse<TProductCategory[]>

//   if (!results?.data?.length) {
//     execute_kw('product.category', 'create', [{name}])
//     const err = new Error(`No ${name} category found, attempted to create one, please refresh page`)
//     reject(err)

//     throw err
//   }

//   results.data.forEach((cat) => {
//     results[cat.name] = cat
//   })

//   resolve(results.data[0])
// })

// cache[name] = promise
// cache[name] = await promise

// return cache[name]
// }

export function useCategory(getter: () => Promise<TProductCategory>) {
  const [res, setRes] = useState<TProductCategory | null>(null)
  useEffect(() => {
    getter().then(setRes)
  }, [setRes, getter])

  return res
}

export const usePriceCategories = () => {
  const fixedPercent = useCategory(() => getCategory(Categories.FIXED_PERCENT))
  const variablePercent = useCategory(() => getCategory(Categories.VARIABLE_PERCENT))
  const fixedPrice = useCategory(() => getCategory(Categories.FIXED_PRICE))
  const smallPrice = useCategory(() => getCategory(Categories.SMALL_PRICE))
  const defect = useCategory(() => getCategory(Categories.DEFECT))
  const variablePercent2 = useCategory(() => getCategory(Categories.VARIABLE_PERCENT_2))

  return {
    fixedPercent,
    variablePercent,
    variablePercent2,
    fixedPrice,
    smallPrice,
    defect,
    loaded: fixedPercent?.id && variablePercent?.id && fixedPrice?.id && smallPrice?.id && defect?.id && variablePercent2?.id,
  }
}

export const usePriceCategoriesV2 = () => {
  const originalPrice = useCategory(() => getCategory(CategoriesV2.ORIGINAL_PRICE))
  const variablePercent = useCategory(() => getCategory(CategoriesV2.VARIABLE_PERCENT))
  const smallPrice = useCategory(() => getCategory(CategoriesV2.SMALL_PRICE))
  const defect = useCategory(() => getCategory(CategoriesV2.DEFECT))
  const percent50 = useCategory(() => getCategory(CategoriesV2.PERCENT_50))
  const percent60 = useCategory(() => getCategory(CategoriesV2.PERCENT_60))
  const percent70 = useCategory(() => getCategory(CategoriesV2.PERCENT_70))
  const percent80 = useCategory(() => getCategory(CategoriesV2.PERCENT_80))
  const percent90 = useCategory(() => getCategory(CategoriesV2.PERCENT_90))

  return {
    originalPrice,
    variablePercent,
    smallPrice,
    defect,
    percent50,
    percent60,
    percent70,
    percent80,
    percent90,
    loaded:
      originalPrice?.id &&
      variablePercent?.id &&
      smallPrice?.id &&
      defect?.id &&
      percent50?.id &&
      percent60?.id &&
      percent70?.id &&
      percent80?.id &&
      percent90?.id,
  }
}

const cache2 = {}

export async function getWebsiteCategory(name: string): Promise<TProductCategory> {
  if (cache2[name]) return cache2[name]

  const req = await execute_kw('product.public.category', 'search_read', [[['name', 'ilike', name]]])
  cache2[name] = req.data[0]

  return req.data[0]
}
