import {useCallback, useState} from 'react'
import {useEffectDebounced} from '../lib/utils/hooks'
import BarcodeScanner from './BarcodeScanner'
import Button from './Button'
import Input from './Input'

export default function SearchBar({onChange, setKeyword, keyword, inputRef}) {
  const [showScanner, setShowScanner] = useState<boolean>(false)

  const toggleScan = () => setShowScanner(!showScanner)
  const scanned = useCallback(
    ({decodedText}) => {
      setKeyword(decodedText)
      setShowScanner(false)
    },
    [setKeyword, setShowScanner]
  )

  useEffectDebounced(() => onChange({keyword}), 500, [keyword])

  return (
    <div className="flex flex-col items-start w-full">
      <div className="flex flex-row w-full">
        <Input placeholder="Barcode" value={keyword} ref={inputRef} onChange={(e) => setKeyword(e.target.value)} />
        <Button onClick={toggleScan} className="ml-2">
          Scan
        </Button>
      </div>

      {showScanner && <BarcodeScanner onScanned={scanned} />}
    </div>
  )
}
